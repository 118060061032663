import { render, staticRenderFns } from "./KnowledgeBaseContent.vue?vue&type=template&id=580620a8&scoped=true&"
import script from "./KnowledgeBaseContent.vue?vue&type=script&lang=js&"
export * from "./KnowledgeBaseContent.vue?vue&type=script&lang=js&"
import style0 from "./KnowledgeBaseContent.vue?vue&type=style&index=0&id=580620a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580620a8",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardTitle,VImg,VRow,VSlideGroup,VSlideItem,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('580620a8')) {
      api.createRecord('580620a8', component.options)
    } else {
      api.reload('580620a8', component.options)
    }
    module.hot.accept("./KnowledgeBaseContent.vue?vue&type=template&id=580620a8&scoped=true&", function () {
      api.rerender('580620a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/burnout-assessment/report/content/KnowledgeBaseContent.vue"
export default component.exports