<template>
  <v-row no-gutters justify="center">
    <v-slide-group class="py-4" show-arrows="mobile" style="min-width: 270px">
      <v-slide-item v-for="(item, index) in items" :key="index" class="ma-2">
        <v-tooltip bottom max-width="250px" open-on-click>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              v-bind="attrs"
              v-on="on"
              :id="'card' + index"
              :width="$vuetify.breakpoint.smAndUp ? '300' : '150'"
              class="rounded-xl ma-2"
              @click="openLink(item.link)"
            >
              <v-img
                :id="'image' + index"
                :src="
                  require('@/assets/images/burnoutassessment/knowledgebase/' +
                    item.image +
                    '')
                "
                :height="$vuetify.breakpoint.smAndUp ? '170' : '85'"
                :width="$vuetify.breakpoint.smAndUp ? '300' : '150'"
                eager
              ></v-img>
              <v-card-title class="py-1 px-4 text-body-1 textBackground">
                <p
                  :id="'text' + index"
                  class="ma-0 white--text text-center textOverflow"
                  style="width: 100%"
                >
                  {{ $t(item.title) }}
                </p>
              </v-card-title>
            </v-card>
          </template>
          <span>{{ $t(item.title) }}</span>
        </v-tooltip>
      </v-slide-item>
    </v-slide-group>
  </v-row>
</template>

<script>
import KnowledgeBaseItems from "@/assets/json/BurnoutAssessment/BurnoutKnowledgeBaseDetails.json";

export default {
  name: "KnowledgeBaseContent",
  data() {
    return {
      items: KnowledgeBaseItems
    };
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    }
  }
};
</script>

<style scoped>
.textOverflow {
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  text-overflow: ellipsis;
}

.textBackground {
  background-color: var(--v-primary-base);
}
</style>
