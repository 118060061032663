var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "", justify: "center" } },
    [
      _c(
        "v-slide-group",
        {
          staticClass: "py-4",
          staticStyle: { "min-width": "270px" },
          attrs: { "show-arrows": "mobile" }
        },
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "v-slide-item",
            { key: index, staticClass: "ma-2" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    "max-width": "250px",
                    "open-on-click": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-card",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "rounded-xl ma-2",
                                    attrs: {
                                      id: "card" + index,
                                      width: _vm.$vuetify.breakpoint.smAndUp
                                        ? "300"
                                        : "150"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openLink(item.link)
                                      }
                                    }
                                  },
                                  "v-card",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-img", {
                                  attrs: {
                                    id: "image" + index,
                                    src: require("@/assets/images/burnoutassessment/knowledgebase/" +
                                      item.image +
                                      ""),
                                    height: _vm.$vuetify.breakpoint.smAndUp
                                      ? "170"
                                      : "85",
                                    width: _vm.$vuetify.breakpoint.smAndUp
                                      ? "300"
                                      : "150",
                                    eager: ""
                                  }
                                }),
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass:
                                      "py-1 px-4 text-body-1 textBackground"
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "ma-0 white--text text-center textOverflow",
                                        staticStyle: { width: "100%" },
                                        attrs: { id: "text" + index }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(item.title)) + " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t(item.title)))])]
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }